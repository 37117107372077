import { useState } from 'react';
import { serialize } from 'object-to-formdata';
import { useDispatch, useSelector } from 'react-redux';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ActionButton from '../ActionButton';
import ForwardModal from '../ForwardModal';
import Popup from '../Popup';
import PreviewMailFileModal from '../PreviewMailFileModal';
import PreviewMailModal from '../PreviewMailModal';
import RegisterArriveForm from '../Forms/RegisterArriveForm';
import WarningModal from '../WarningModal';

import { getCorrespondentById } from '../../redux/reducers/correspondents/actionCreators';
import { selectCurrentUserId } from '../../redux/reducers/authentication/selectors';
import { selectUserRole } from '../../redux/reducers/user/selectors';
import * as courriersArriveeAction from '../../redux/reducers/courriersArrivee/actionCreators';
import * as courriersArriveeSelectors from '../../redux/reducers/courriersArrivee/selectors';

import { LIST_OF_ROLES, LIST_OF_ROLES_KEYS, WORKFLOW_STATE } from '../../constants';

const ActionsRow = styled.div`
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 8px;

  button {
    margin-right: 8px;
  }
`;

function RegisterArriveExpandableRows({ data }) {
  const dispatch = useDispatch();

  const isDeleteLoader = useSelector(courriersArriveeSelectors.deleteLoader);
  const isUpdateLoader = useSelector(courriersArriveeSelectors.updateLoader);
  const pagination = useSelector(courriersArriveeSelectors.pagination);
  const userId = useSelector(selectCurrentUserId);
  const userRole = useSelector(selectUserRole(userId));

  const [isConfirmDelete, setConfirmDelete] = useState(false);
  const [isConfirmClose, setConfirmClose] = useState(false);
  const [isConfirmProcess, setConfirmProcess] = useState(false);
  const [isConfirmProgress, setConfirmProgress] = useState(false);
  const [isEditPopupShow, setEditPopupShow] = useState(false);
  const [isShowFile, setShowFile] = useState(false);
  const [isShowForward, setShowForward] = useState(false);
  const [isShowPreview, setShowPreview] = useState(false);

  const role = LIST_OF_ROLES[userRole];
  const isMe = data?.user_id === userId;

  const showFilePreviewHandler = () => {
    setShowFile(true);
  };

  const previewMailHandler = () => {
    setShowPreview(true);
  };

  const deleteRecordHandler = async () => {
    await dispatch(courriersArriveeAction.deleteById({ id: data?.id }));
    await dispatch(courriersArriveeAction.getAll({ limit: pagination.per_page, page: 1 }));
  };

  const processMailHandler = async () => {
    await dispatch(courriersArriveeAction.updateById({
      id: data?.id,
      user_id: userId,
      workflow_state: WORKFLOW_STATE.PROCESSED,
    }));
    setConfirmProcess(false);
  };

  const inProgressMailHandler = async () => {
    await dispatch(courriersArriveeAction.updateById({
      id: data?.id,
      user_id: userId,
      workflow_state: WORKFLOW_STATE.IN_PROGRESS,
    }));
    setConfirmProcess(false);
  };

  const forwardMailHandler = async () => {
    await dispatch(courriersArriveeAction.updateById({
      id: data?.id,
      is_confidential: true,
      user_id: userId,
      workflow_state: WORKFLOW_STATE.TRANSMITTED_GENERAL_MANAGER,
    }));
    setShowForward(false);
  };

  const closeMailHandler = async () => {
    await dispatch(courriersArriveeAction.updateById({
      id: data?.id,
      user_id: userId,
      workflow_state: WORKFLOW_STATE.CLOSED,
    }));
    setConfirmDelete(false);
  };

  const editRecordHandler = async () => {
    await dispatch(getCorrespondentById(data.correspondant_id));
    setEditPopupShow(true);
  };

  const onSubmitHandler = async (data) => {
    const body = serialize(data);
    await dispatch(courriersArriveeAction.updateById(body));
    await dispatch(courriersArriveeAction.getAll({ limit: pagination.per_page, page: 1 }));
    setEditPopupShow(false);
  };

  const deleteRecord = (
    <ActionButton
      onClick={() => setConfirmDelete(true)}
      icon="trash"
      title="Supprimer"
      variant="danger"
    />
  );

  const editRecord = (
    <ActionButton
      onClick={() => editRecordHandler(data)}
      icon="pen"
      title="Modifier"
    />
  );

  const showFilePreview = (
    <ActionButton
      onClick={showFilePreviewHandler}
      icon="file"
      title="Aperçu fichier"
    />
  );

  const previewMail = (
    <ActionButton
      onClick={() => previewMailHandler(data)}
      icon="eye"
      title="Détails"
    />
  );

  const processMail = (
    <ActionButton
      onClick={() => setConfirmProcess(true)}
      icon="eye"
      title="Traiter"
    />
  );

  const inProgressMail = (
    <ActionButton
      onClick={() => setConfirmProgress(true)}
      icon="pen"
      title="En cours"
    />
  );

  const closeMail = (
    <ActionButton
      onClick={() => setConfirmClose(true)}
      icon="times"
      title="Classer"
      variant="warning"
    />
  );

  const forwardMail = (
    <ActionButton
      onClick={() => setShowForward(true)}
      icon="forward"
      title="Transmettre au DG"
    />
  );

  const workflowState = data?.workflow_state?.toLowerCase();
  let actions = null;

  if (role === LIST_OF_ROLES_KEYS.STANDARDISTE && workflowState === WORKFLOW_STATE.TRANSMITTED_SECRETARY) {
    actions = deleteRecord;
  } else if (role === LIST_OF_ROLES_KEYS.SECRETAIRE && workflowState === WORKFLOW_STATE.TRANSMITTED_SECRETARY) {
    actions = (
      <>
        {showFilePreview}
        {previewMail}
        {forwardMail}
        {editRecord}
      </>
    );
  } else if (role === LIST_OF_ROLES_KEYS.SECRETAIRE && workflowState === WORKFLOW_STATE.TRANSMITTED_SERVICE_MANAGER) {
    actions = (
      <>
        {showFilePreview}
        {previewMail}
        {inProgressMail}
        {processMail}
      </>
    );
  } else if (role === LIST_OF_ROLES_KEYS.SECRETAIRE && workflowState !== WORKFLOW_STATE.TRANSMITTED_SECRETARY) {
    actions = (
      <>
        {showFilePreview}
        {previewMail}
      </>
    );
  } else if (role === LIST_OF_ROLES_KEYS) {
    actions = (
      <>
        {showFilePreview}
        {previewMail}
        {forwardMail}
        {editRecord}
        {deleteRecord}
      </>
    );
  } else if (role === LIST_OF_ROLES_KEYS.DIRECTEUR_GENERAL && workflowState === WORKFLOW_STATE.TRANSMITTED_GENERAL_MANAGER) {
    actions = (
      <>
        {showFilePreview}
        {previewMail}
        {processMail}
        {editRecord}
        {closeMail}
        {deleteRecord}
      </>
    );
  } else if (role === LIST_OF_ROLES_KEYS.DIRECTEUR_GENERAL) {
    actions = (
      <>
        {showFilePreview}
        {previewMail}
      </>
    );
  } else if (role === LIST_OF_ROLES_KEYS.DIRECTEUR_SERVICE && workflowState === WORKFLOW_STATE.TRANSMITTED_SERVICE_MANAGER) {
    actions = (
      <>
        {showFilePreview}
        {previewMail}
        {inProgressMail}
        {processMail}
        {editRecord}
        {closeMail}
        {deleteRecord}
      </>
    );
  } else if (role === LIST_OF_ROLES_KEYS.DIRECTEUR_SERVICE && isMe) {
    actions = (
      <>
        {showFilePreview}
        {previewMail}
      </>
    );
  } else if (role === LIST_OF_ROLES_KEYS.AGENT_SERVICE && workflowState === WORKFLOW_STATE.TRANSMITTED_SERVICE_AGENT) {
    actions = (
      <>
        {showFilePreview}
        {previewMail}
        {processMail}
        {editRecord}
      </>
    );
  } else if (role === LIST_OF_ROLES_KEYS.AGENT_SERVICE && isMe) {
    actions = (
      <>
        {showFilePreview}
        {previewMail}
      </>
    );
  }

  const preview = [
    { label: 'Créé le', key: 'created_at' },
    { label: 'Date correspondance', key: 'date_correspondance' },
    { label: 'Date édition', key: 'date_edition' },
    { label: 'mis à jour le', key: 'updated_at' },
    { label: 'Priorité', key: 'priorite' },
    { label: 'Référence', key: 'reference' },
    { label: 'Fichier', key: 'scan' },
    { label: 'Séquence', key: 'sequence' },
    { label: 'Statut', key: 'workflow_state' },
    { label: 'Actions demandées', key: 'actions' },
    { label: 'Imputations', key: 'users' },
    { label: 'Commentaire', key: 'commentaire' },
  ];

  return (
    <ActionsRow className="row-actions">
      {actions}

      <Popup isShow={isEditPopupShow} onHide={() => setEditPopupShow(false)} title="Formulaire courrier">
        <RegisterArriveForm
          data={{
            ...pick(data, ['id', 'correspondant_id', 'date_correspondance', 'date_edition', 'reference', 'objet']),
            action_courriers_attributes: data?.actions?.map((val) => val?.id),
            user_courriers_attributes: data?.users?.map((val) => val?.id),
          }}
          onClose={() => setEditPopupShow(false)}
          onSubmit={onSubmitHandler}
          isEdit
        />
      </Popup>

      <PreviewMailFileModal
        data={data}
        isVisible={isShowFile}
        onClose={() => setShowFile(false)}
        title="Prévisualisation Courrier"
      />

      <PreviewMailModal
        data={data}
        isVisible={isShowPreview}
        onClose={() => setShowPreview(false)}
        previewKeysArray={preview}
        title="Aperçu courrier"
      />

      <WarningModal
        description="Êtes-vous sûr de vouloir supprimer ce courrier?"
        isLoading={isDeleteLoader}
        isVisible={isConfirmDelete}
        onClose={() => setConfirmDelete(false)}
        onSubmit={deleteRecordHandler}
        title="Supprimer le courrier"
        submitButtonText="Supprimer"
      />

      <WarningModal
        description="Voulez-vous vraiment classer ce courrier?"
        isLoading={isUpdateLoader}
        isVisible={isConfirmClose}
        onClose={() => setConfirmClose(false)}
        onSubmit={closeMailHandler}
        title="Classer le courrier"
        submitButtonText="Classer"
      />

      <WarningModal
        description="Voulez-vous vraiment traiter ce courrier?"
        isLoading={isUpdateLoader}
        isVisible={isConfirmProcess}
        onClose={() => setConfirmProcess(false)}
        onSubmit={processMailHandler}
        title="Traiter le courrier"
        submitButtonText="Traiter"
      />

      <WarningModal
        description="Ce courrier est-il vraiment en cours de traitement?"
        isLoading={isUpdateLoader}
        isVisible={isConfirmProgress}
        onClose={() => setConfirmProgress(false)}
        onSubmit={inProgressMailHandler}
        title="En cours de traitement"
        submitButtonText="Oui"
      />

      <ForwardModal
        description="Êtes-vous sûr de vouloir transmettre directement ce courrier au DG? Si oui, ce courrier sera considéré comme CONFIDENTIEL"
        isLoading={isUpdateLoader}
        isVisible={isShowForward}
        onClose={() => setShowForward(false)}
        onSubmit={forwardMailHandler}
        title="Transférer au DG"
      />
    </ActionsRow>
  );
}

RegisterArriveExpandableRows.propTypes = {
  data: PropTypes.shape({
    correspondant_id: PropTypes.number,
    id: PropTypes.number,
    scan: PropTypes.string,
    user_id: PropTypes.number,
    workflow_state: PropTypes.string,
    actions: PropTypes.shape([]),
    users: PropTypes.shape([]),
  }),
};

RegisterArriveExpandableRows.defaultProps = {
  data: {},
};

export default RegisterArriveExpandableRows;
